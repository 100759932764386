<template>
  <header>

    <img
      class="back"
      @click="back"
      src="@/assets/images/icon-fh.png"

      alt=""
      v-if="!isHideBack"
    />

    <img
      class="menu"
      src="@/assets/images/icon-cd.png"
      @click="menuShow"
      alt=""
    />


    <div class="search-wapper" @click="onSearch($route.meta.searchType)">
      {{$route.name == 'OpenSearch'? $route.query.keyword:'请输入关键词搜索' }}
      <a href="javascript:;">
        <img
          class="search-icon"
          src="@/assets/images/icon-search.png"
          alt=""
        />
      </a>
    </div>

    <h1 class="logo" @click="toLogin">
      <a href="javascript:void(0);">
        {{ userInfo.avatar }}
        <img
          style="border-radius: 50%"
          src="/img/wxshare/logo.png"
          alt=""
          v-if="!userInfo.userImg"
        />
        <img style="border-radius: 50%" :src="userInfo.userImg" alt="" v-else />
      </a>
    </h1>
    
  </header>
</template>

<script lang="ts">
import { defineComponent, inject, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    isHideBack: {
      default: false,
      type: Boolean,
    },
    isEmit: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["handle-click", "handle-headide"],
  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    // const hide = inject("handleHide");
    function back() {
      if (props.isEmit) {
        ctx.emit("handle-click");
      } else {
        if(window.history.length <= 1){
          router.push({path: "/"})
        }else{
          router.back();
        }
        
      }
    }
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    function toLogin() {
      if (!props.isEmit) {
        // redirect personal
        if (userInfo && userInfo.id) {
       
          
          router.push({
            name: "Personal",
            query: {
              uid: userInfo.id,
            },
          });
        } else {
          if(route.path != '/login'){
            router.push({
              name: "Login",
              query: {
                redirect: route.fullPath
              }
            })
          }else{
            router.push({
              name: "Login"
            })
          }
          
        }
      }
    }

    const show = inject("handleMenuHide");

    function menuShow() {
      (show as () => void)();
    }
    function onSearch(type: string) {
      ctx.emit("handle-headide")
      router.push({
        name:"Search",
        query:{ type }
      })
    }
    return {
      back,
      toLogin,
      menuShow,
      userInfo,
      onSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
header {
  width: 375px;
  height: 55px;
  box-sizing: border-box;
  position: fixed;
  padding: 0 10px;
  padding-left: 20px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 10;
}
header .back {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

header .menu {
  width: 24px;
  height: 24px;
}

header .search-wapper {
  width: 250px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  border-radius: 15px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 38px;
  font-size: 16px;
  border: none;
  outline: none;
  color: #666;
  background-color: #f2f2f2;
  margin: 0 15px;
}

header .search-icon {
  position: absolute;
  width: 16px;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.tcp-skin {
  width: 100%;
}

.logo {
  width: 27px;
  height: 27px;
  margin: 0;
}
.logo a {
  display: block;
  width: 27px;
  height: 27px;
}
.logo img {
  width: 27px;
  height: 27px;
  display: block;
}
</style>
